.redoc-wrap {
    display: flex !important;
    flex: 1 1 auto !important;
    overflow: hidden !important;
    border: none !important;
    margin: 0px;
    border-radius: 0px;
    box-shadow: none;
}

.api-doc-title{
    font-size: 1rem;
    color: #2E2E2E !important;
    font-weight: bold;
    a{
        color: #2E2E2E !important;
    }
}

.api-doc-img{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 484px;
}

.api-doc-img2{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 533px;
}

.sc-XxNYO{
    display: none;
}
.sc-dwsnSq{
    width: 17rem !important;
}

.doc-card .lsrCard{
    box-shadow: none;
    .header{
        padding: 0;
        .title{
            border: none;
            padding: 0;
        }
    }
    .content{
        padding: 0px;
    }
}

.get{
    background-color: #2294BA !important;
}

.post{
    background-color: #51B0A1 !important;
}

.ijzeoA{
    padding-bottom: 0px !important;
    .sc-furwcr{
        margin-bottom: 0px !important;
        font-size: 1.5rem;
    }
}

.sc-eCImPb{
    padding: 1.25rem 0px !important;
}

.menu-content{
    background-color: #F5F7FA !important;
    display: block;
    flex: 0 0 auto !important;
    position: relative !important;
    .sc-fHeRUh{
        >ul {
            >li {
                .sc-dtDOqo{
                    &:hover{
                        background-color: #DEE3EC;
                    }
                    .sc-gIDmLj{
                        line-height: unset !important;
                        height: auto !important;
                        //padding: 1px 5px !important;
                        max-height: 20px !important;
                        border-radius: 4px !important;
                        font-weight: 400 !important;
                    }                    
                }
                .active{
                    background-color: #DEE3EC;
                }
            }
        }
    }
}

.sc-dUbtfd{
    font-size: 1.125rem !important;
}

.sc-cLpAjG{
    display: none;
}

.api-content{
    margin-left: 0px;
    position: absolute !important;
    flex: 1 1 auto !important;
    height: 100%;
    overflow: auto;
    width: calc(100% - 16.25rem) !important;
    transform: translate3d(16.25rem, 0, 0);
    overflow: auto !important;
}

.sc-hKwDye{
    h2{
        font-weight: bold !important;
        font-size: 1.125rem !important;
        color: #2E2E2E !important;
        margin: 30px 0 30px !important;
        vertical-align: middle;
        // &::before{
        //     content: '';
        //     display: inline-block;
        //     width: 2px;
        //     height: 1rem;
        //     background-color: #2294BA;
        //     margin-right: 0.625rem;
        //     vertical-align: middle;
        //     border-radius: 2px;
        // }
    }
    .sc-iqseJM{
        border-bottom: 1px solid #DCDFE6 !important;
        opacity: 1;
    }
    table{
        tr{
            .sc-hBUSln{
                .sc-ieecCq{
                    &::before{
                    }
                    &::after{
                        width: 6px !important;
                        height: 6px !important;
                        border-radius: 10px;
                    }
                }
                :nth-child(2){
                    color: #606266 !important;
                }
                .sc-TBWPX{
                    color:  #F84949 !important;
                    margin-left: 25px !important;
                }
            }
            .sc-bkkeKt{
                padding: 5px 0 !important;
                border-bottom-color: #DCDFE6;
                .sc-fWCJzd{
                    color: #C0C4CC;
                }
                .sc-iAKWXU{
                    color:  #606266;
                }
            }
        }
    }
}
.sc-jRQBWg{
    
    height: 100% !important;
    border-radius: 4px;
    background-color: rgba(34, 148, 186, 0.06) !important;
    opacity: 1 !important;
    padding: 16px 10px !important;
    margin:0 30px !important;
    .sc-kYHfwS{
        cursor: default;
    }
    .sc-dVNjXY{
        pointer-events: none;
        cursor: none;
        padding: 0.5rem 0.625rem;
        background-color: rgba(34, 148, 186, 0.06) !important;
        border: 1px solid rgba(34, 148, 186, 0.5) !important;
        border-radius: 4px !important;
        color: #2294BA !important;
        .sc-jHkVzv{
            border-radius: 4px;
            padding: 2px 14px;
        }
        svg{
            display: none;
            margin-right: 0px !important;
            polygon{
                fill: #2294BA !important;
            }
        }
    }
    .sc-kDTinF{
        color: #2E2E2E !important;
        font-size: 1.125rem !important;
    }
    .sc-caiLqq{
        .tab-success{
            color: #2294BA !important;
            border-color: #2294BA !important;
            min-width: 50px !important;
            padding: 1px 0.625rem !important;
            background-color: transparent !important;
            font-weight: 400 !important;
        }
        .react-tabs__tab{
            color: #2294BA !important;
            border-color: #2294BA !important;
            min-width: 50px !important;
            padding: 1px 10px !important;
            background-color: transparent !important;
        }
        .react-tabs__tab-panel{
            background-color: transparent !important;
            margin-top: 20px;
            :first-child{
                padding: 0px !important;
                .sc-hiwPVj{
                    background-color: transparent !important;
                    span{
                        color: #606266 !important;
                        font-size: 1rem;
                        font-weight: 400;
                        top:0px;
                        left: 0px;
                        position: relative;
                    }
                    .sc-eJwWfJ{
                        margin-top: 10px;
                        background-color: rgba(34, 148, 186, 0.06) !important;
                        border: 1px solid rgba(34, 148, 186, 0.5);
                        border-radius: 4px;
                        padding: 0.5rem 0.625rem !important;
                        color: #2294BA;
                    }
                    color: black !important;
                }
                .sc-gGCDDS{
                    margin-top: 30px;
                    .sc-efQSVx{
                        color: rgba(96, 98, 102, 1);
                        opacity: 1 !important;
                        text-align: left;
                        button{
                            &:first-child{
                                padding-right: 10px !important;
                            }
                            &:hover,&:focus{
                                color: #2294BA !important;
                            }
                        }
                    }
                    .redoc-json{
                        margin-top: 15px !important;
                        padding: 10px 5px !important;
                        background-color: #2E2E2E !important;
                        code{
                            color:white !important;
                            font-size: 0.875rem;
                            span{
                                color:white !important;
                                opacity: 1 !important;
                            }
                            ul{
                                li{
                                    span{
                                        color:white
                                    }
                                }
                            }
                        }
                    }                
                }                
            }


        }
    }
}

.markdown-body{
    img{
        width: 45rem;        
    }

}
.customer-server{
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
    border: none;
    margin: 0px;
    border-radius: 0px;
    box-shadow: none;
    position: relative;
}
.markdown-nav-bar{ 
    margin: 0px;
    border-radius: 0px;
    flex: 0 0 auto;
    position: relative;
    width: 20rem;
    background-color: #F5F7FA;
    height: 100%;
    overflow: auto;
}
.customer-server-markdown{
    position: absolute;
    flex: 1 1 auto;
    height: 100%;
    overflow: auto;
    width: calc(100% - 20rem);
    transform: translate3d(20rem, 0px, 0px);
    padding-top: 20px;
    padding-bottom: 100px;
    padding-left: 20px;
    background-color: white !important;
}
.markdown-navigation .title-anchor:hover, .markdown-navigation .title-anchor.active{
    color: unset;
    background-color: transparent;
}
.markdown-navigation .title-anchor:hover{
    color: #007fff;
    background-color: #DEE3EC !important;
}
.markdown-navigation .title-level1{
    font-weight: bold;
}